<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Village (New Survay No)</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Village">
                <v-select v-model="village" placeholder="None" label="village_name" :options="allVillageOption"
                  @input="handlevillage($event)">
                  <template #option="{ village_name, surveynonew }">
                    <span class="font-weight-bolder"> {{ village_name }} </span><span>({{ surveynonew }})</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>New Survey No</label>
              <b-form-input v-model="newsurveyno" disabled />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Old Survey No</label>
              <b-form-input v-model="oldsurveyno" disabled />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>OP FP No</label>
              <b-form-input v-model="opno" disabled />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>FP No</label>
              <b-form-input v-model="fpno" disabled />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Account No / Khata No</label>
              <b-form-input v-model="accountno" disabled />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Project Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Project Name">
                <b-form-input v-model="projectname" placeholder="Enter Project Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Phase Name</label>

              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Phase Name">
                <b-form-input v-model="phasename" placeholder="Enter Phase Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Phase Image (1100px * 700px)</label>
              <b-form-file v-model="phaseattachment" placeholder="Select Phase" drop-placeholder="Drop file here..."
                accept="image/*" @input="handleFileChange($event, 'phaseattachment', 'projectmaster')" />
            </b-form-group>
            <attachment :data="phaseattachment" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>No. Of Plots</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="No of Plots">
                <b-form-input v-model="noofplot" placeholder="Enter No. " />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>No. Of Gardens</label>

              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="No of Gardens">
                <b-form-input v-model="noofgarden" placeholder="No. of Gardens" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Select Bank</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Bank">
                <v-select v-model="bank" placeholder="None" label="name" multiple :options="bankLedgerOption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Price (per sq. yard)</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="price ">
                <b-form-input v-model="pricepersqyd" placeholder="Enter Price (per sq. yard)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Project Value (in %)</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Value">
                <b-form-input v-model="projectvalue" placeholder="Enter Project Value" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Development Plan Value(in %)</label>
              <b-form-input v-model="developmentplanvalue" placeholder="Enter Development Plan Value" />
            </b-form-group>
          </b-col>
          <b-col :md="cornerValue == 'Yes' || cornerphase == 'Yes' ? '3' : '6'">
            <b-form-group>
              <label>Corner Phase</label>
              <v-select v-model="cornerphase" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name" :options="['Yes', 'No']" @input="cornerhandelclick($event)" />
            </b-form-group>
          </b-col>
          <b-col md="3" :style="cornerphase == 'Yes' ? '' : 'display:none'">
            <b-form-group>
              <label>Corner phase Value(%)</label>
              <b-form-input v-model="cornerphasevalue" placeholder="Enter Corner phase Value(%)" />
            </b-form-group>
          </b-col>
          <b-col :md="gardenValue == 'Yes' || gardenphase == 'Yes' ? '3' : '6'">
            <b-form-group>
              <label>Garden Phase</label>
              <v-select v-model="gardenphase" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name" :options="['Yes', 'No']" @input="gardenhandelclick($event)" />
            </b-form-group>
          </b-col>
          <b-col md="3" :style="gardenphase == 'Yes' ? '' : 'display:none'">
            <b-form-group>
              <label>Garden phase Value(%)</label>
              <b-form-input v-model="gardenphasevalue" placeholder="Enter Garden phase Value(%)" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Project Area(In Sq. MTR)</label>
              <label style="color: red !important">*</label>

              <b-row>
                <b-col md="12">
                  <validation-provider #default="{ errors }" rules="required" name="Project Area">
                    <b-form-input v-model="plottedareainsqmtr" @input="totalProjectMtr('projectArea')"
                      placeholder="In Sq. MTR" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <label>Project Area(In Sq. Yard)</label>
              <label style="color: red !important">*</label>

              <b-row>
                <b-col md="12">
                  <validation-provider #default="{ errors }" rules="required" name="Project Area in Yd">
                    <b-form-input v-model="plottedareainsqyd" @input="totalProjectYd('projectArea')"
                      placeholder="In Sq. Yard" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Project Super Build Up Area(In Sq. MTR)</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Build Up Area">
                <b-row>
                  <b-col md="12">
                    <b-form-input v-model="projectsuperbuildupareasqmtr" @input="totalProjectMtr('superBuildArea')"
                      placeholder="In Sq. MTR" />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              <label>Project Super Build Up Area(In Sq. Yard)</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Build Up Area in Yd">
                <b-row>
                  <b-col md="12">
                    <b-form-input v-model="projectsuperbuildareasqyd" @input="totalProjectYd('superBuildArea')"
                      placeholder="In Sq. Yard" />
                  </b-col>
                </b-row>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Project Area(In Sq. MTR)</label>
              <label style="color: red !important">*</label>
              <b-row>
                <b-col md="12">
                  <b-form-input v-model="totalprojectareamtr" disabled placeholder="In Sq. MTR" />
                </b-col>
              </b-row>
              <label>Total Project Area(In Sq. MTR)</label>
              <label style="color: red !important">*</label>
              <b-row>
                <b-col md="12">
                  <b-form-input v-model="totalprojectareayd" disabled placeholder="In Sq. Yard" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Min. Booking Amount</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Min. Booking Amount">
                <b-form-input v-model="bookingamount" placeholder="Min. Booking Amount" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Company Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Comapany Name">
                <v-select v-model="companyname" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name" :options="companyOption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>NA Order No</label>
              <b-form-input v-model="naorderno" placeholder="Enter NA Order No." />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>NA Order Date</label>
              <flat-pickr v-model="naorderdate" class="form-control" :config="{
                dateFormat: 'd/m/Y',
                defaultDate: new Date(),
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Project Type</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Project Type">
                <v-select v-model="projecttype" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name" :options="['Plot', 'Raw Land', 'Villa', 'Appartment', 'Farm House']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>RERA No</label>
              <b-form-input v-model="rerano" placeholder="Enter RERA No" />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Taluka</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Taluka">
                <b-form-input v-model="taluka" placeholder="Enter Taluka" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required|digits:6" name="Pin Code">
                <b-form-input v-model="pincode" maxlength="6" @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Enter Pin Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="City">
                <v-select v-model="city" label="City" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cityOption" @input="clickArea($event)" placeholder="None" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Area">
                <v-select v-model="area" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="areaOption"
                  placeholder="None" @input="handlePincode()" label="PostOfficeName" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <v-select v-model="state" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="None"
                  :options="stateOptions" label="State" @input="handleCity()" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Assign To CP</label>
              <v-select v-model="assigntoCp" placeholder="None" :options="assignToCpOption" @input="handleAllCp($event)"
                multiple label="fullname" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Assign To Franchise</label>
              <v-select v-model="assigntoFr" placeholder="None" :options="assignToFrOption" @input="handleAllFr($event)"
                multiple label="fullname" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Assign To Employee</label>
              <v-select v-model="assigntoEmp" placeholder="None" :options="assignToEmpOption"
                @input="handleAllEmp($event)" multiple label="fullname" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Project Status</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Project Status">
                <v-select v-model="projectstatus" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name" :options="['Pre Launching', 'Launching', 'Running', 'Sold Out']" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Project Banner</label>
              <b-form-file v-model="projectbanner" placeholder="Select Project Banner"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFileChange($event, 'projectbanner', 'projectmaster')" />
            </b-form-group>
            <attachment :data="projectbanner" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Project Brochure</label>
              <b-form-file v-model="projectboucher" placeholder="Select Project Brochure"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFileChange($event, 'projectboucher', 'projectmaster')" />
            </b-form-group>
            <attachment :data="projectboucher" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Logo</label>
              <b-form-file v-model="projectlogo" placeholder="Select Logo" drop-placeholder="Drop file here..."
                accept="image/*" @input="handleFileChange($event, 'projectlogo', 'projectmaster')" />
            </b-form-group>
            <attachment :data="projectlogo" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Qr Code</label>
              <b-form-file v-model="qrcode" placeholder="Select Qr Code" drop-placeholder="Drop file here..."
                accept="image/*" @input="handleFileChange($event, 'qrcode', 'projectmaster')" />
            </b-form-group>
            <attachment :data="qrcode" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Receipt Header</label><small> (2551 × 354 pixel) </small>
              <b-form-file v-model="receipt_header" placeholder="Select Receipt Header"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFileChange($event, 'receipt_header', 'projectmaster')" />
            </b-form-group>
            <attachment :data="receipt_header" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Receipt Footer</label><small> (2551 × 354 pixel) </small>
              <b-form-file v-model="receipt_footer" placeholder="Select Receipt Footer"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFileChange($event, 'receipt_footer', 'projectmaster')" />
            </b-form-group>
            <attachment :data="receipt_footer" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Receipt Background Logo</label><small>(835 × 613)</small>
              <b-form-file v-model="receipt_background_logo" placeholder="Select Receipt Footer"
                drop-placeholder="Drop file here..." accept="image/*" @input="
                  handleFileChange($event, 'receipt_background_logo', 'projectmaster')
                  " />
            </b-form-group>
            <attachment :data="receipt_background_logo" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Map Image</label>
              <b-form-file v-model="mapimage" placeholder="Select Document" drop-placeholder="Drop file here..."
                @input="handleFileChange($event, 'mapimage', 'projectmaster')" accept="image/*" />
            </b-form-group>
            <attachment :data="mapimage" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>KMZ File</label>
              <b-form-file v-model="kmzfile" placeholder="Select Document" drop-placeholder="Drop file here..."
                @input="handleFileChange($event, 'kmzfile', 'projectmaster')" accept="image/*" />
            </b-form-group>
            <attachment :data="kmzfile" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Map URL</label>
              <b-form-input v-model="iframe" placeholder="Enter Map URL." />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Project Description</label>
              <b-form-textarea v-model="description" placeholder="Enter Description" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Location Highlights</label>
              <b-form-textarea v-model="location_highlights" placeholder="Enter Location Highlights" />
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Video URL</label>
              <b-form-input

                v-model="videourl"
                placeholder="Enter Video URL."
              />
            </b-form-group>
          </b-col> -->
          <b-col md="12">
            <b-card-code title="Booking Type" no-body />
          </b-col>
          <b-col>
            <b-form-group>
              <b-form-checkbox v-model="bookingtype.pp" inline>
                Part Payment
              </b-form-checkbox>
              <b-form-checkbox v-model="bookingtype.fp" inline>
                Full Payment
              </b-form-checkbox>
              <b-form-checkbox v-model="bookingtype.emi" inline> EMI </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col cols="12" v-if="bookingtype && bookingtype.emi">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table class="table table-bordered" style="min-width: 15cm; max-height: 15cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>month</th>
                    <th>rate in %</th>
                    <th>Enable/Disable</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in EMI" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <div class="d-flex justify-content-between align-items-center">
                        <b-form-input v-model="item.fromMonth" type="number" placeholder="Enter Number Of Month" />
                        <b class="p-1"> To </b>
                        <b-form-input v-model="item.toMonth" type="number" placeholder="Enter Number Of Month" />
                      </div>
                    </td>
                    <td>
                      <b-form-input v-model="item.rate" type="number" placeholder="Enter Rate" />
                    </td>
                    <td>
                      <div class="h-100 w-100 d-flex justify-content-center">
                        <b-form-checkbox v-model="item.enable" class="custom-control-primary" name="check-button"
                          switch>
                        </b-form-checkbox>
                      </div>
                    </td>
                    <td>
                      <b-form-textarea v-model="item.remarks" placeholder="Enter Remarks" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeEMIRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addEMIRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Gallery" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table class="table table-bordered" style="min-width: 15cm; max-height: 15cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Images</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in gallery" :key="id" style="max-height: 100px">
                    <td>{{ id + 1 }}</td>
                    <td style="max-height: 50px">
                      <b-form-file v-model="item.attachment" placeholder="Select Image"
                        drop-placeholder="Drop file here..." @input="
                          handleMultiFileChange($event, 'gallery', 'projectmaster', id)
                          " />
                      <attachment :data="item.attachment" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeGalleryRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addGalleryRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="VideoUrl" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 200px">
              <table class="table table-bordered">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Video Url</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in videourl" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input v-model="item.url" placeholder="Enter VideoUrl" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeUrlRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addUrlRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col md="12">
            <b-card-code title="Conditions" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 200px">
              <table class="table table-bordered" style="min-width: 15cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Condition Name</th>
                    <th>Condition</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in conditions" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input v-model="item.name" placeholder="Enter Document Name" />
                    </td>
                    <td>
                      <b-form-file v-model="item.attachment" placeholder="Select Document"
                        drop-placeholder="Drop file here..." @input="
                          handleMultiFileChange($event, 'conditions', 'projectmaster', id)
                          " />
                      <attachment :data="item.attachment" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeConditionRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addConditionRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col md="12">
            <b-card-code title="Documantation" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table class="table table-bordered" style="min-width: 15cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Document Name</th>
                    <th>Documentation</th>
                    <th>Show / Hide</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in data" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input v-model="item.name" placeholder="Enter Document Name" />
                    </td>
                    <td>
                      <b-form-file v-model="item.attachment" placeholder="Select Document"
                        drop-placeholder="Drop file here..." @input="
                          handleMultiFileChange($event, 'project', 'projectmaster', id)
                          " />
                      <attachment :data="item.attachment" />
                    </td>
                    <td>
                      <b-form-checkbox v-model="item.show" class="custom-control-primary" name="check-button" switch>
                      </b-form-checkbox>
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Highlights" no-body />
          </b-col>

          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 200px">
              <table class="table table-bordered">
                <thead class="text">
                  <tr>
                    <th>Sr. No</th>
                    <th>Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in highlights" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input v-model="item.name" placeholder="Enter Deatils" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeHighlightsRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addHighlightsRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>

          <b-col md="12">
            <b-card-code title="Amenities" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table class="table table-bordered" style="min-width: 15cm; max-height: 15cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Amenities</th>
                    <th>Attachment</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in amenities" :key="id" style="max-height: 100px">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input v-model="item.details" placeholder="Enter Deatils" />
                    </td>
                    <td style="max-height: 50px">
                      <b-form-file v-model="item.attachment" placeholder="Select Document"
                        drop-placeholder="Drop file here..." @input="
                          handleMultiFileChange($event, 'amenities', 'projectmaster', id)
                          " />
                      <attachment :data="item.attachment" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeAmenitiesRow(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addAmenitiesRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="submitForm" class="mr-4" :disabled="check">
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../../../components/Attechment.vue";

export default {
  components: {
    flatPickr,
    BFormCheckboxGroup,

    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
    BFormCheckbox,
  },
  data() {
    return {
      check: false,
      iframe: "",
      phaseattachment: "",
      projectbanner: "",
      // projectMOU: "",
      projectlogo: "",
      projectboucher: "",
      conditions: "",
      qrcode: "",
      description: "",
      location_highlights: "",
      noofgarden: 0,
      bookingamount: 0,
      noofplot: 0,
      bank: "",
      assignToCpOption: [],
      assignToFrOption: [],
      assignToEmpOption: [],
      assignto_data: [],
      assigntoCp: [],
      assigntoFr: [],
      assigntoEmp: [],

      projectname: "",
      phasename: "",
      pricepersqyd: "",
      projectvalue: "",
      developmentplanvalue: "",
      gardenValue: "",
      cornerValue: "",
      gardenphase: "",
      gardenphasevalue: "",
      cornerphase: "",
      cornerphasevalue: "",
      plottedareainsqmtr: "",
      plottedareainsqyd: "",
      projectsuperbuildupareasqmtr: "",
      projectsuperbuildareasqyd: "",
      totalprojectareamtr: "",
      totalprojectareayd: "",
      companyname: "",
      oldsurveyno: "",
      newsurveyno: "",
      accountno: "",
      naorderno: "",
      naorderdate: moment(new Date()).format("DD/MM/yyyy"),
      projecttype: "",
      rerano: "",
      opno: "",
      fpno: "",
      village: "",
      taluka: "",
      mapimage: "",
      kmzfile: "",

      pincode: "",
      city: "",
      state: "",
      cityOption: [],
      areaOption: [],
      subassignto: [],
      conditions: [
        {
          name: "",
          attachment: "",
        },
      ],
      highlights: [
        {
          name: "",
        },
      ],
      gallery: [
        {
          attachment: "",
        },
      ],
      projectstatus: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      companyOption: [],
      villageOption: [],
      allVillageOption: [],
      allUser: [],
      ifEdit: false,
      data: [
        {
          name: "",
          attachment: "",
          show: false,
        },
      ],
      cp: false,
      fr: false,
      emp: false,
      allcp: [],
      allfr: [],
      videourl: [
        {
          url: "",
        },
      ],
      // area: "",
      // possessionStatus: "",
      // contractStatus: "",
      // city: "",
      // contactPerson: "",
      // contactNo: "",
      // emailId: "",
      // gstNo: "",
      // lat: "",
      // lng: "",
      // propertyType: "",
      // property: "",
      // bhk: "",
      // propertysizesqft: "",
      // propertysizesqyd: "",
      // rate: "",
      // mouReceived: "",
      // mou: "",
      // selectedTeam: "",
      // propertyFor: "",
      // availableFrom: "",
      // keyAvailability: "",
      // projectType: "",
      // teamOption: [],
      receipt_header: null,
      receipt_footer: null,
      receipt_background_logo: null,
      fromState: "",
      area: "",
      areaOption: [],
      StateData: [],
      stateOptions: [],
      newsurveynoOption: [],
      assignto: [],
      type: "",
      userId: "",
      allFrUser: [],
      allCpUser: [],
      test: {},
      bankLedgerOption: [],
      assigntoCpIds: [],
      amenities: [
        {
          details: "",
          attachment: "",
        },
      ],
      resObj: {},
      bookingtype: {
        fp: false,
        pp: false,
        emi: false,
      },
      EMI: [],
      allEmp: [],
    };
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const data = JSON.parse(localStorage.getItem("userData"));
    const role = data.role;
    this.userId = data.id;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Project") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/crm/projectmaster");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/crm/projectmaster");
          }
        }
      });
    }
    this.getVillage();
    this.getBank();
    await this.getCP();
    this.getEmployee();
    this.getFr();

    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getFr() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getFranchise`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.assignToFrOption = response.data.data;
          this.assigntoFr = this.allfrForPayload
            ? this.assignToFrOption
            : this.assigntoFr;
          this.assignToFrOption.unshift({ fullname: "All", id: "All" });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getEmployee() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.assignToEmpOption = response.data.data;
          this.assigntoEmp = this.allempForPayload
            ? this.assignToEmpOption
            : this.assigntoEmp;
          this.assignToEmpOption.unshift({ fullname: "All", id: "All" });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getBank() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/bankledger`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.bankLedgerOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    handleCpFr() {
      this.assignToOption = [];

      // if (this.cp == false && this.fr == true) {
      //   this.assignToOption = [];
      //   this.assignToOption.push({ name: "All" });
      //   this.assignToOption = [...this.assignToOption, ...this.allfr];
      // } else if (this.cp == true && this.fr == false) {
      //   this.assignToOption = [];

      //   this.assignToOption.push({ name: "All" });
      //   this.assignToOption = [...this.assignToOption, ...this.allcp];
      // } else if (this.cp == true && this.fr == true) {
      //   this.assignToOption = [];

      //   this.assignToOption.push({ name: "All" });
      //   this.assignToOption = [
      //     ...this.assignToOption,
      //     ...this.allfr,
      //     ...this.allcp,
      //   ];
      // }
      this.assignToOption.push({ fullname: "All" });
      if (this.fr) {
        this.assignToOption = [...this.assignToOption, ...this.allfr];
      }
      if (this.cp) {
        this.assignToOption = [...this.assignToOption, ...this.allcp];
      }
      if (this.emp) {
        this.assignToOption = [...this.assignToOption, ...this.allEmp];
      }
      if (this.assignToOption.length == 1) {
        this.assignToOption = [];
      }
    },
    handleAllCp(e) {
      if (e && e.length > 0) {
        if (e[e.length - 1].fullname == "All") {
          this.assigntoCp = this.assignToCpOption;
          this.assigntoCp.shift();
          this.allcpForPayload = 1;
        } else {
          this.allcpForPayload = 0;
        }
      } else {
        this.allcpForPayload = 0;
      }
    },
    handleAllFr(e) {
      if (e && e.length > 0) {
        if (e[e.length - 1].fullname == "All") {
          this.assigntoFr = this.assignToFrOption;
          this.assigntoFr.shift();
          this.allfrForPayload = 1;
        } else {
          this.allfrForPayload = 0;
        }
      } else {
        this.allfrForPayload = 0;
      }
    },
    handleAllEmp(e) {
      if (e && e.length > 0) {
        if (e[e.length - 1].fullname == "All") {
          this.assigntoEmp = this.assignToEmpOption;
          this.assigntoEmp.shift();
          this.allempForPayload = 1;
        } else {
          this.allempForPayload = 0;
        }
      } else {
        this.allempForPayload = 0;
      }
    },
    handleAssignto() {
      if (!this.allcpForPayload || !this.allfrForPayload || !this.allempForPayload) {
        this.assignto_data.map((item) => {
          if (item.role == "channelpartner" && !this.allcpForPayload) {
            let index = this.assignToCpOption.findIndex((inner) => item.id == inner.id);
            console.log(index);
            this.assigntoCp.push(this.assignToCpOption[index]);
          } else if (item.role == "franchise" && !this.allfrForPayload) {
            this.assigntoFr.push(item);
          } else if (item.role == "employee" && !this.allempForPayload) {
            this.assigntoEmp.push(item);
          }
        });
      }
    },
    handlevillage(e) {
      if (e) {
        this.oldsurveyno = e.surveynoold;
        this.newsurveyno = e.surveynonew;
        this.accountno = e.khatano;
        this.opno = e.orignalplotno;
        this.fpno = e.fpno;
      }
    },
    async getEditValue() {
      // console.log(this.$route.params.id, "id====");
      await axios({
        method: "GET",
        url: `${this.baseApi}/getProjectById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          // if (json.data.length) {
          // }
          this.getEditItems(json.data.data[0]);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      this.phaseattachment = item.phaseattachment;
      this.projectlogo = item.projectlogo;
      this.projectboucher = item.projectboucher;
      this.projectbanner = item.projectbanner;
      this.qrcode = item.qrcode;
      this.projectname = item.projectname;
      this.noofplot = item.noofplot;
      this.bank = item.bank_data;
      this.phasename = item.phasename;
      this.pricepersqyd = item.pricepersqyd;
      this.projectvalue = item.projectvalue;
      this.developmentplanvalue = item.developmentplanvalue;
      this.gardenValue = item.gardenValue;
      this.cornerValu = item.cornerValu;
      this.gardenphase = item.gardenphase;
      this.gardenphasevalue = item.gardenphasevalue;
      this.cornerphase = item.cornerphase;
      this.cornerphasevalue = item.cornerphasevalue;
      this.plottedareainsqmtr = item.plottedareainsqmtr;
      this.plottedareainsqyd = item.plottedareainsqyd;
      this.projectsuperbuildupareasqmtr = item.projectsuperbuildupareasqmtr;
      this.projectsuperbuildareasqyd = item.projectsuperbuildareasqyd;
      this.totalprojectareamtr = item.totalprojectareamtr;
      this.totalprojectareayd = item.totalprojectareayd;
      this.companyname = item.company;
      this.oldsurveyno = item.oldsurveyno;
      this.accountno = item.accountno;
      this.naorderno = item.naorderno;
      this.naorderdate = item.naorderdate;
      this.projecttype = item.projecttype;
      this.rerano = item.rerano;
      this.opno = item.opfpno.opno;
      this.mapimage = item.mapimage;
      this.kmzfile = item.kmzfile;
      this.fpno = item.opfpno.fpno;
      this.village = item.village;
      this.handlevillageAtEdit(item.village);
      this.taluka = item.taluka;
      this.city = item.city;
      this.area = item.area;
      this.state = item.state;
      this.pincode = item.pincode;
      this.projectstatus = item.projectstatus;
      this.data = item.data;
      this.iframe = item.iframe;
      this.amenities = item.amenities ? JSON.parse(item.amenities) : [];
      this.allcpForPayload = item.is_cp;
      this.allfrForPayload = item.is_franchise;
      this.allempForPayload = item.is_emp;
      this.receipt_header = item.receipt_header;
      this.receipt_footer = item.receipt_footer;
      this.receipt_background_logo = item.receipt_background_logo;

      this.videourl = item.videourl ? JSON.parse(item.videourl) : [];
      this.conditions = item.conditions ? JSON.parse(item.conditions) : [];
      this.gallery = item.gallery ? JSON.parse(item.gallery) : [];
      this.highlights = item.highlights ? JSON.parse(item.highlights) : [];
      this.EMI = item.emi ? JSON.parse(item.emi) : [];
      this.bookingtype = item.bookingtype ? JSON.parse(item.bookingtype) : {};
      console.log(this.bookingtype);

      this.description = item.description;
      this.location_highlights = item.location_highlights;
      this.noofgarden = item.noofgarden;
      this.bookingamount = item.bookingamount;
      this.assignto = item.assignto.filter((user_id) => user_id);
      this.assignto_data = item.assignto_data;
      this.assigntoCp = this.allcpForPayload
        ? [...this.assignToCpOption].slice(1)
        : this.assigntoCp;
      this.assigntoFr = this.allfrForPayload
        ? [...this.assignToFrOption].slice(1)
        : this.assigntoFr;
      this.assigntoEmp = this.allempForPayload
        ? [...this.assignToEmpOption].slice(1)
        : this.assigntoEmp;
      this.handleCity();
      this.handleArea();

      this.handleAssignto();
      this.data.map((item) => {
        item.show = item.show ? true : false;
      });
      // this.clickPincode()
      // this.clickPerPincode()
      // this.contractStatus = item.contractstatus;
      // this.city = item.city;
      // this.contactPerson = item.contactperson;
      // this.contactNo = item.contactno;
      // this.emailId = item.emailid;
      // this.gstNo = item.gstno;bankLedgerOption

      // this.area = item.area;
      // this.lat = item.lat;
      // this.lng = item.lng;
      // this.propertyType = item.propertytype;
      // this.Property = item.property;
      // this.bhk = item.bhk;
      // this.propertysizesqft = item.propertysizesqft;
      // this.propertysizesqyd = item.propertysizesqyd;
      // this.rate = item.rate;
      // this.mouReceived = item.moureceived;
      // this.mou = item.mou;
      // this.selectedTeam = item.listingby;
      // this.propertyFor = item.propertyfor;
      // this.possessionStatus = item.possessionstatus;bankLedgerOption
      // this.availableFrom = item.availablefrom;
      // this.keyAvailability = item.keyavailability;
      // this.projectMOU = item.projectmou;

      // this.projectType = item.ptype;
    },
    clickArea(e) {
      this.areaOption = [];
      this.StateData.map((item) => {
        if (item.City == e) {
          this.areaOption.push(item.PostOfficeName);
        }
      });
    },
    handlevillageAtEdit(obj) {
      if (obj) {
        this.oldsurveyno = obj.surveynoold;
        this.newsurveyno = obj.surveynonew;
        this.accountno = obj.khatano;
        this.opno = obj.orignalplotno;
        this.fpno = obj.fpno;
      }
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }

      // this.areaOption = []
      // this.StateData.map((item) => {
      //     if (item.City == e) {
      //         this.areaOption.push(item.PostOfficeName)
      //     }
      // })
    },
    handlePincode() {
      if (this.fromState == true) {
        this.pincode = this.area.Pincode;
      }
    },
    async handleCity() {
      this.areaOption = [];
      this.cityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },

    async clickPincode() {
      this.area = "";
      this.city = "";
      this.state = "";
      this.areaOption = [];
      this.cityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.pincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.pincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.cityOption = [];
            this.StateData = response.data.data;
            this.areaOption = response.data.data;
            this.state = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.cityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    reset() {
      //   (this.$refs.zoomer as PinchScrollZoom).setData({
      //   scale: 1,
      //   originX: 0,
      //   originY: 0,
      //   translateX: 0,
      //   translateY: 0
      // });
    },
    cornerhandelclick(e) {
      this.cornerValue = e;
    },
    gardenhandelclick(e) {
      this.gardenValue = e;
    },
    handleImage() {
      this.visible = true;
    },
    handleDownlineCp(item) {
      item.children.map((inner) => {
        this.subassignto.push(inner.id);
        if (inner.children.length > 0) {
          this.handleDownlineCp(inner);
        }
      });
    },
    handleChildren() {
      this.assigntoCpIds.map((item) => {
        this.subassignto.push(item.id);
        if (item.children.length > 0) {
          this.handleDownlineCp(item);
        }
      });
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          let document = this.data.filter((item) => {
            item.show = item.show ? 1 : 0;
            return item.attachment;
          });
          this.assigntoCpIds = [...this.assigntoCp];
          this.subassignto = [];
          this.test = {};
          this.handleChildren();
          this.empAndFrIds = [...this.assigntoFr, ...this.assigntoEmp].map((item) => {
            return item.id;
          });
          this.assigntoCpIds = this.assigntoCpIds.map((item, index) => {
            return item.id;
          });

          this.subassignto = [...this.empAndFrIds, ...this.subassignto].join(",");
          this.EMI = this.EMI.filter((item) => item.fromMonth || item.toMonth);
          console.log(this.EMI);
          data = {
            branch: 1,
            builderid: 0,
            // company: 1,
            active: 1,
            phaseattachment: this.phaseattachment,
            projectboucher: this.projectboucher,
            projectbanner: this.projectbanner,
            projectlogo: this.projectlogo,
            qrcode: this.qrcode,
            projectname: this.projectname,
            phasename: this.phasename,
            noofplot: this.noofplot,
            mapimage: this.mapimage,
            kmzfile: this.kmzfile,
            bank_id: this.bank ? this.bank.map((item) => item.id).join(",") : "",
            pricepersqyd: this.pricepersqyd,
            projectvalue: this.projectvalue,
            developmentplanvalue: this.developmentplanvalue,
            gardenphase: this.gardenphase,
            gardenphasevalue: this.gardenphasevalue,
            cornerphase: this.cornerphase,
            cornerphasevalue: this.cornerphasevalue,
            plottedareainsqmtr: this.plottedareainsqmtr,
            plottedareainsqyd: this.plottedareainsqyd,
            projectsuperbuildupareasqmtr: this.projectsuperbuildupareasqmtr,
            projectsuperbuildareasqyd: this.projectsuperbuildareasqyd,
            totalprojectareamtr: this.totalprojectareamtr,
            totalprojectareayd: this.totalprojectareayd,
            companyname: this.companyname.id,
            oldsurveyno: this.oldsurveyno,
            newsurveyno: this.newsurveyno,
            accountno: this.accountno,
            naorderno: this.naorderno,
            naorderdate: this.naorderdate,
            projecttype: this.projecttype,
            receipt_header: this.receipt_header,
            receipt_footer: this.receipt_footer,
            receipt_background_logo: this.receipt_background_logo,
            rerano: this.rerano,
            purchaseid: this.village.id,
            opfpno: {
              opno: this.opno,
              fpno: this.fpno,
            },
            village: this.village,
            taluka: this.taluka,
            city: this.city.City,
            area: this.area.PostOfficeName,
            state: this.state.State,
            pincode: this.pincode,
            projectstatus: this.projectstatus,
            data: document,
            assignto: [...this.assigntoCpIds, ...this.empAndFrIds],
            subassignto: this.subassignto,
            iframe: this.iframe,
            is_cp: this.allcpForPayload,
            is_franchise: this.allfrForPayload,
            is_emp: this.allempForPayload,

            videourl: JSON.stringify(this.videourl),
            amenities: JSON.stringify(this.amenities),
            conditions: JSON.stringify(this.conditions),
            gallery: JSON.stringify(this.gallery),
            highlights: JSON.stringify(this.highlights),
            bookingtype: this.bookingtype,
            emi: JSON.stringify(this.EMI),
            description: this.description,
            location_highlights: this.location_highlights,
            noofgarden: this.noofgarden,
            bookingamount: this.bookingamount,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.check = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/project/${this.$route.params.id}`
              : `${baseApi}/project`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.check = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                    ? `${json.data.success}`
                    : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/master/crm/projectmaster");
            })
            .catch((error) => {
              this.check = false;

              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async init() {
      this.getState();
      this.getCompany();
    },
    // async getFranchise() {
    //   const requestOptions = {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //     url: `${this.baseApi}/getFranchise`,
    //   };
    //   await axios(requestOptions)
    //     .then((response) => {
    //       this.allFrUser = response.data.data;
    //       response.data.data.map((item) => {
    //         if (item.parent == this.userId) {
    //           this.allfr.push(item);
    //         }
    //       });
    //     })
    //     .catch((error) => console.log(error, "error"));
    // },
    async getCP() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUserByParentId/${this.userId}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.assignToCpOption = response.data.data.filter(
            (item) => item.role == "channelpartner"
          );
          this.assignToCpOption.unshift({ fullname: "All", id: "All" });
          this.assigntoCp = this.allcpForPayload
            ? this.assignToCpOption.slice(1)
            : this.assigntoCp;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getVillage() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getvillagedata`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.resObj = {};
          this.allVillageOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCompany(id) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCompany`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.companyOption = response.data.data;
          this.companyOption.map((item) => {
            if (item.id == id) {
              this.companyname = item.name;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleFileChange(e, name, type) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this[name] = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    addHighlightsRow() {
      this.highlights.push({
        name: "",
      });
    },
    removeHighlightsRow(index) {
      this.highlights.splice(index, 1);
    },
    addEMIRow() {
      this.EMI.push({
        remakrs: "",
        enable: true,
        rate: 0,
        fromMonth: 0,
        toMonth: 0,
      });
    },
    removeEMIRow(index) {
      this.EMI.splice(index, 1);
    },
    addConditionRow() {
      this.conditions.push({
        name: "",
        attachment: "",
      });
    },
    removeConditionRow(index) {
      this.conditions.splice(index, 1);
    },
    addRow() {
      this.data.push({
        name: "",
        attachment: "",
        show: false,
      });
    },
    removeRow(index) {
      this.data.splice(index, 1);
    },
    removeUrlRow(index) {
      this.videourl.splice(index, 1);
    },
    addUrlRow() {
      this.videourl.push({
        url: "",
      });
    },
    removeGalleryRow(index) {
      this.gallery.splice(index, 1);
    },
    addGalleryRow() {
      this.gallery.push({
        attachment: "",
      });
    },
    addAmenitiesRow() {
      this.amenities.push({
        details: "",
        attachment: "",
      });
    },
    removeAmenitiesRow(index) {
      this.amenities.splice(index, 1);
    },
    totalProjectMtr(e) {
      if (e == "projectArea") {
        this.plottedareainsqyd = (parseFloat(this.plottedareainsqmtr) * 1093) / 1000;
      }

      if (e == "superBuildArea") {
        this.projectsuperbuildareasqyd =
          (parseFloat(this.projectsuperbuildupareasqmtr) * 1093) / 1000;
      }
      this.totalprojectareamtr =
        parseFloat(this.plottedareainsqmtr) +
        parseFloat(this.projectsuperbuildupareasqmtr);
      this.totalprojectareayd =
        parseFloat(this.plottedareainsqyd) + parseFloat(this.projectsuperbuildareasqyd);
    },
    totalProjectYd(e) {
      if (e == "projectArea") {
        this.plottedareainsqmtr = (parseFloat(this.plottedareainsqyd) * 1000) / 1093;
      }
      if (e == "superBuildArea") {
        this.projectsuperbuildupareasqmtr =
          (parseFloat(this.projectsuperbuildareasqyd) * 1000) / 1093;
      }

      this.totalprojectareayd =
        parseFloat(this.plottedareainsqyd) + parseFloat(this.projectsuperbuildareasqyd);
      this.totalprojectareamtr =
        parseFloat(this.plottedareainsqmtr) +
        parseFloat(this.projectsuperbuildupareasqmtr);
    },
    async handleMultiFileChange(e, name, type, index) {
      this.check = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (name == "amenities") {
            this.amenities[index].attachment =
              process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else if (name == "conditions") {
            this.conditions[index].attachment =
              process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else if (name == "gallery") {
            this.gallery[index].attachment =
              process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          } else {
            this.data[index].attachment =
              process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          }
          this.check = false;
        })
        .catch((error) => {
          this.check = false;
        });
    },
    onClickBack() {
      this.$router.push("/master/crm/projectmaster");
    },
  },
};
</script>
